import React from "react"
import { Formik } from "formik"
import styled from "styled-components"

const StyledFormWrapper = styled.form`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin: 20px 0;
`

const StyledInput = styled.input`
font-size: 15px;
color: #000000;
margin: 5px auto 20px;
padding: 5px 10px;
width: 70%;

&:active,:focus {
  outline-color: #000000;
}

@media screen and (min-width: 768px) {
  width: 40%;
}
`

const StyledInputLabel = styled.label`
color: #000000;
font-size: 13px;
font-weight: 600;
margin: 3px auto;
`

const StyledTextArea = styled.textarea`
font-size: 15px;
color: #000000;
padding: 10px;
width: 70%;
min-height: 100px;
margin: 5px auto 20px;
font-family: Montserrat;

&:active,:focus {
  outline-color: #000000;
}

@media screen and (min-width: 768px) {
  min-height: 200px;
}
`

const StyledSubmitBtn = styled.button`
outline: none;
background: #da1e35;
color: #ffffff;
cursor: pointer;
padding: 10px 30px;
border: none;
margin: 0 auto 20px;
font-weight: 500;
`

const StyledError = styled.span`
font-size: 13px;
color: #da1e35;
`

const ContactPage = () => {
  return (
    <>
      <Formik
        initialValues={{ name: "", topic: "", email: "", message: "" }}
        validate={values => {
          const errors = {}
          if (!values.email) {
            errors.email = "Pole wymagane"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Niewłaściwy adres e-mail"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // alert(JSON.stringify(values, null, 2))
            setSubmitting(false)
          }, 400)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <StyledFormWrapper onSubmit={handleSubmit}>
            <StyledInputLabel for={'name'}>Imię i nazwisko</StyledInputLabel>
            <StyledError>{errors.name && touched.name && errors.name}</StyledError>
            <StyledInput
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
            />
            <StyledInputLabel for={'name'}>Temat</StyledInputLabel>
            <StyledError>{errors.topic && touched.topic && errors.topic}</StyledError>
            <StyledInput
              type="text"
              name="topic"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.topic}
            />
            <StyledInputLabel for={'email'}>E-mail</StyledInputLabel>
            <StyledError>{errors.email && touched.email && errors.email}</StyledError>
            <StyledInput
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <StyledInputLabel for={'message'}>Treść wiadomości</StyledInputLabel>
            <StyledError>{errors.message && touched.message && errors.message}</StyledError>
            <StyledTextArea
              type="textarea"
              name="message"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.message}
            />
            <StyledSubmitBtn type="submit" disabled={isSubmitting}>
              Wyślij
            </StyledSubmitBtn>
          </StyledFormWrapper>
        )}
      </Formik>
    </>
  )
}

export default ContactPage
